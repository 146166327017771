import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import { InputBox } from '../components/common';
import { Link } from 'react-router-dom';
import validate from "../validations/FormValidationRules";
import useFormvalidation from '../validations/FormValidation'
import AuthAction from '../redux/actions/AuthAction';
import { useDispatch } from 'react-redux';
import { IoMdHeart } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import jwtDecode from "jwt-decode";
import { FaArrowLeftLong } from "react-icons/fa6";
import bubble1 from "../../src/images/bubble 01.png"
import bubble2 from "../../src/images/bubble 02.png"
import bubble3 from "../../src/images/bubblle 03.png"
import apple from "../../src/images/apple.png"
import facebook from "../../src/images/facebook.png"
// import GoogleLogin from '../components/common/Googlelogin';
import { FaApple } from "react-icons/fa";
import logo1 from "../../src/images/Logo (1).png";
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
const Login = () => {
    const dispatch = useDispatch()
    const { LoginApi, othersourcelogindataapi } = AuthAction();
    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
    }, []);
    const [user, setUser] = useState(null); // State to store Google user info
    const [fbUser, setFbUser] = useState(null)
    const handleGoogleLoginSuccess = (credentialResponse) => {
        try {
            const decoded = jwtDecode(credentialResponse?.credential);
            const input_data = {
                source: "google",
                ...(localStorage.getItem("sessionId") != null && { session_id: localStorage.getItem("sessionId") }),
                source_uid: decoded && decoded.sub && decoded.sub,
                name: decoded && decoded.name && decoded.name,
                mailid: decoded && decoded.email && decoded.email
            }
            dispatch(othersourcelogindataapi(input_data))
            setUser(decoded); // Store user details
        } catch (error) {
        }
    };
    const handleGoogleLogout = () => {
        googleLogout();
        setUser(null); // Clear user data
    };
    const googleLoginStyles = {
        container: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
            padding: "10px",
            backgroundColor: "#f5f5f5",
            border: "1px solid #ddd",
            borderRadius: "8px",
            cursor: "pointer",
        },
        button: {
            backgroundColor: "#4285f4",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold",
            border: "none",
            padding: "10px 20px",
            borderRadius: "4px",
        },
    };

    const navigate = useNavigate();
    const [formValues, setFormValues] = React.useState(
        // email: "",
        // password: "",

        localStorage.getItem("verification_method") == 1 ? {

            "phone": "",
            "password": "",
            ...(localStorage.getItem("sessionId") != null && { session_id: localStorage.getItem("sessionId") })
        }
            :
            {
                "email": "",
                "password": "",
                ...(localStorage.getItem("sessionId") != null && { session_id: localStorage.getItem("sessionId") })
            }
    );
    const { values, errors, handleChange, setErrors, handleSubmit } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );
    function handleNext() {
        dispatch(LoginApi(values, errors, setErrors))
        
    }
    const loginbackaction = () => {
        navigate(-1)
    }
    const handleFacebookLoginSuccess = (response) => {
        const input_data = {
          source: "facebook",
          source_uid: response.id,
          name: response.name,
          mailid: response.email,
          ...(localStorage.getItem("sessionId") != null && { session_id: localStorage.getItem("sessionId") })
        };
        setFbUser(response); // Store user details in state
        dispatch(othersourcelogindataapi(input_data)); // Dispatch action with user data
      };
    
      const handleFacebookLoginFailure = (error) => {
      };
    
      const handleFacebookLogout = () => {
        setFbUser(null); // Clear Facebook user data
      };
    
    return (
        <Container>
            <Helmet>
                <title>{localStorage.getItem("title")} - login</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            <div className='form_main_container '>
                <div className="formsdata_container login-width">
                    {/* <div className='bubble1imag'><img src={bubble1}></img></div>
                    <div className='bubble2imag'><img src={bubble2}></img></div>
                    <div className='bubble3imag'><img src={bubble3}></img></div> */}
                    <div className='fomdataaa'>
                        <div lg={5} className="right-side loginregister">
                            <div onClick={loginbackaction}>
                                <div className='loginbackbtnicon'><FaArrowLeftLong />
                                    <span className='loginbackbtn' >Back</span>
                                </div>

                            </div>
                            <h4 className="login_text">Log in </h4>
                            <h6 className='loginscreen_goodtosee'>Good to see you back! <IoMdHeart className='loginheart' /></h6>
                            {
                                localStorage.getItem("verification_method") == 1 ?
                                    <Col lg={12}>
                                        <InputBox label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    :
                                    <Col lg={12}>
                                        <InputBox label={"Email"} id={"email"} handleChange={handleChange} errors={errors} />
                                    </Col>
                            }
                            <InputBox label={"Password"} id={"password"} handleChange={handleChange} errors={errors} />

                            <div className='product-detail-buttongroup login-register-btn' onClick={handleSubmit}>
                                <div className={`product-detail-button form_submit_button_padding primaryColor `}>
                                    Login
                                </div>
                            </div>
                            <div className='loginscreen_forgotpassword'>
                                <Link to="/password-reset" className='signUpNavLink text-decoration-none  text-underline-none2'>
                                    Forgot your password?
                                </Link>
                            </div>
                            <div className='mt-5'>
                                {/* <div className='linkwithggogle-apple'> */}
                                    {/* Continue with Apple */}
                                    {/* <div> */}
                                        {/* <img className='linkwithggogle-appleicon' src={apple}></img> */}
                                        {/* <FaApple className='linkwithggogle-appleicon' /> */}
                                    {/* </div> */}
                                {/* </div> */}
                                
                                {/* <div className='linkwithggogle-apple'>
                                    <FacebookLogin
                                        appId="1341496777213464"
                                        className='facebook'
                                    />
                                    <div>
                                        <img className='linkwithggogle-appleicon' src={facebook}></img>
                                    </div>
                                </div> */}
                                {/* {!fbUser ? (
                                    <FacebookLogin
                                        appId="1341496777213464" // Replace with your Facebook App ID
                                        onSuccess={handleFacebookLoginSuccess}
                                        onFail={handleFacebookLoginFailure}
                                        fields="name,email,picture" // Request specific user details
                                        render={({ onClick }) => (
                                            <button onClick={onClick} className="facebook-login-button">
                                                Login with Facebook
                                            </button>
                                        )}
                                    />
                                ) : (
                                    <div>
                                        <p>Welcome, {fbUser.name}!</p>
                                        <p>Email: {fbUser.email}</p>
                                        <img src={fbUser.picture?.data?.url} alt="Profile" />
                                        <button onClick={handleFacebookLogout} className="facebook-logout-button">
                                            Logout from Facebook
                                        </button>
                                    </div>
                                )} */}
                                {!user ? (
                                    // <div className='linkwithggogle-apple'>
                                    <div className="google-login-container">
                                        <GoogleLogin
                                            onSuccess={handleGoogleLoginSuccess}
                                            type="standard" // standard or icon
                                            theme="filled_white" // outline, filled_blue, or filled_black
                                            size="large" // large, medium, or small
                                            text="Continue With" // signin_with, signup_with, continue_with, or signin
                                            shape="pill" // rectangular, pill, circle, or square
                                            logo_alignment="center" // left or center
                                            width="300px" // button width in pixels
                                            locale="en" // language for button
                                            useOneTap={true} // enable One-Tap
                                         
                                            render={(renderProps) => (
                                                <button
                                                    onClick={renderProps.onClick}
                                                    disabled={renderProps.disabled}
                                                    className="custom-google-login-btn"
                                                >
                                                    Login from Google
                                                </button>
                                            )}
                                        />
                                    </div>

                                    // {/* <img className='linkwithggogle-appleicon' src={logo1} alt="google" /> */}
                                    // </div>
                                ) : (
                                    <div className='linkwithggogle-apple' onClick={handleGoogleLogout}>
                                        Logout from Google
                                        <img className='linkwithggogle-appleicon' src={logo1} alt="google" />
                                    </div>
                                )}

                            </div>
                            {/* <GoogleLogin /> */}
                            <div className='login-register'>Don’t have an account?
                                <Link to="/register" className='signUpNavLink text-decoration-none  text-underline-none2'>
                                    <span className='loginscreen_forgotpassword marginleft'>Sign up</span>
                                </Link>
                            </div>


                        </div>
                    </div>



                </div>
            </div>

        </Container >



    );
};

export default Login;