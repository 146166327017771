import React, { useState } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import androdimg from "../../images/images.png"
// import androdimg from '../../images/androidapp.png'
import iosimg from '../../images/ios-store.png'
import { SiMinutemailer } from "react-icons/si";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import logo from '../../images/bg_image.webp';
import { useSelector } from "react-redux";
import Newsletterform from "./Newsletterform";
// import appstore from '../../images/appstore.webp';
// import iosstore from '../../images/ios-store.webp';
import { BsWhatsapp, BsLinkedin, BsInstagram, BsFacebook } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import pay1 from "../../images/pay1.png"
import pay2 from "../../images/pay2.png"
import pay3 from "../../images/pay3.png"


const Footer = () => {
  const footerdata = useSelector(state => state.masterdata.footerdata);
  const categoryData = useSelector(state => state.masterdata.categoryData);
  const navdata = useSelector(state => state.masterdata.navdata);
  const navigationstatus = useSelector(state => state.masterdata.NavigationStatus);
  const mobileapplink = navdata.filter(ele => ele.list_key == "mobile_app")
  const footercategories = navdata.filter(ele => ele.list_key == "medium")
  const andriod = mobileapplink.filter(ele => ele.name == "Android")
  const IOS = mobileapplink.filter(ele => ele.name == "IOS")
  const [show, setShow] = useState(7);
  const [buttonShow, setButtonShow] = useState(true);
  const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  const handelViewMore = () => {
    setShow(-1);
    setButtonShow(false);
  };
  const handelViewLess = () => {
    setShow(7);
    setButtonShow(true);
  };
  const navfooter = ["Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games", "Toys& games",]
  return (
    <>

      <div className="footerSecondContainer">


        <div className="footer_content_main">
          <div >

            <Col lg={12} className="footer-logo-main">
            <img
                referrerPolicy="no-referrer"
                    className="footerLogoimage"
                src={
                  navigationstatus &&
                  navigationstatus.find(
                    (ele) => ele.component === "global-setting"
                  )?.sectionResult[0]?.Footer_image || logo
                }
                onError={(e) => {
                  e.currentTarget.src = logo; // Fallback to logo on error
                }}
              />
            
              <div className="footer_content">
                Got question? Contact us 24/7
              </div>
            </Col>
          </div>
          <div >
            <div>
              <div className="footer_headings">
                Policies
              </div>
              {
                footerdata.filter(ele=>ele.posts_type =="policies").map(ele => {
                  return (
                    <div className="footer_content">
                      <NavLink to={`/policies/${ele.list_key}`} className="text-decoration-none ">
                        {ele.title}
                      </NavLink>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {/* <div >
            <div>
              <div className="footer_headings">
                Company
              </div>
              <div className="footer_content">
                About company
              </div>
              <div className="footer_content">
                Our team
              </div>
              <div className="footer_content">
                Careers
              </div>
              <div className="footer_content">
                Contact us
              </div>
              <div className="footer_content">
                News
              </div>
            </div>
          </div>
          <div >
            <div>
              <div className="footer_headings">
                Company
              </div>
              <div className="footer_content">
                About company
              </div>
              <div className="footer_content">
                Our team
              </div>
              <div className="footer_content">
                Careers
              </div>
              <div className="footer_content">
                Contact us
              </div>
              <div className="footer_content">
                News
              </div>
            </div>
          </div> */}

        </div>
        <div className="footercategories">

          {
            footercategories.map(ele => {
              return (
                <div className="footercat">
                  <NavLink to={ele.url} className="text-decoration-none" >
                    <span className="footercat_space">{ele.name}</span>/
                  </NavLink>
                </div>
              )
            })
          }


        </div>

        <div className="footerbotton_content">

          <div className="websitecopyright">{localStorage.getItem("website_copyright")}</div>
          <div>
            <img className="footerpay" src={pay1}></img>
            <img className="footerpay" src={pay2}></img>
            <img className="footerpay" src={pay3}></img>
          </div>
        </div>
      </div >
    </>
  )
}

export default Footer;
