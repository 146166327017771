import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { use } from "react";
const Apppage = () => {
    const footerdata = useSelector(state => state.masterdata.footerdata);
    const data = footerdata.filter(ele => ele.posts_type == "blog")
    return (
        <div>
                {
                    data.length > 0 ?
                        <>
                            <div dangerouslySetInnerHTML={{ __html: data[0].body }} />
                        </> :
                        <div className='custom-height'></div>
                }

       
        </div>

    )
}
export default Apppage;